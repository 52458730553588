@font-face {
	font-family: 'MontserratVariable';
	font-style: normal;
	font-weight: 100 900;
	font-display: swap;
	src: url('Montserrat-VariableFont_wght.ttf') format('ttf');
}
@font-face {
	font-family: 'MontserratVariable';
	font-style: italic;
	font-weight: 100 900;
	font-display: swap;
	src: url('Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
}

/* static fonts */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url('Montserrat-Thin.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url('Montserrat-ThinItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url('Montserrat-ExtraLight.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url('Montserrat-ExtraLightItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('Montserrat-Light.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url('Montserrat-LightItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('Montserrat-Regular.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('Montserrat-Italic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('Montserrat-Medium.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url('Montserrat-MediumItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('Montserrat-SemiBold.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url('Montserrat-SemiBoldItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('Montserrat-Bold.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('Montserrat-BoldItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('Montserrat-ExtraBold.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url('Montserrat-ExtraBoldItalic.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('Montserrat-Black.ttf') format('ttf');
}
@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url('Montserrat-BlackItalic.ttf') format('ttf');
}
