@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~leaflet/dist/leaflet.css';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ededed;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-fill-custom-white:-webkit-autofill {
	-webkit-background-clip: text;
	-webkit-text-fill-color: #ffffff;
}

/* Stellt sicher, dass die ganze Seite im Druck angezeigt wird */
@media print {
	html,
	body {
		width: 100%;
		height: auto; /* Auto sorgt dafür, dass die gesamte Seite berücksichtigt wird */
		margin: 0;
		padding: 0;
		overflow: visible; /* Verhindert, dass Inhalte abgeschnitten werden */
	}

	#root {
		width: 100%;
		height: auto; /* Stellt sicher, dass der Root-Container die nötige Höhe hat */
	}

	@page {
		size: landscape; /* Setzt das Papierformat auf Querformat */
		margin: 12mm 3mm;
	}

	@page portrait {
		size: portrait;
	}
	.portrait-print {
		page: portrait;
	}
}

.rdp {
	margin: 0 !important;
	--rdp-accent-color: #2c81ff !important;
}

/* TODO: Make a more beautiful Icon-"resizer" */

.resize-handle {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 10px;
	height: 10px;
	background-color: #333;
	cursor: se-resize;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	font-weight: inherit !important;
}

.react-tooltip {
	z-index: 1000;
}

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;
		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;
		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;
		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;
		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;
		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;
		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;
		--ring: 215 20.2% 65.1%;
		--radius: 0.5rem;
	}

	.dark {
		--background: 224 71% 4%;
		--foreground: 213 31% 91%;
		--muted: 223 47% 11%;
		--muted-foreground: 215.4 16.3% 56.9%;
		--accent: 216 34% 17%;
		--accent-foreground: 210 40% 98%;
		--popover: 224 71% 4%;
		--popover-foreground: 215 20.2% 65.1%;
		--border: 216 34% 17%;
		--input: 216 34% 17%;
		--card: 224 71% 4%;
		--card-foreground: 213 31% 91%;
		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 1.2%;
		--secondary: 222.2 47.4% 11.2%;
		--secondary-foreground: 210 40% 98%;
		--destructive: 0 63% 31%;
		--destructive-foreground: 210 40% 98%;
		--ring: 216 34% 17%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply font-sans antialiased bg-background text-foreground;
	}
}

@layer base {
	:root {
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--chart-6: 340 65% 60%; /* Pink-Rot */
		--chart-7: 295 55% 50%; /* Violett */
		--chart-8: 260 45% 40%; /* Dunkles Blau-Violett */
		--chart-9: 130 45% 40%; /* Waldgrün */
		--chart-10: 85 55% 50%; /* Frisches Grün */
		--chart-11: 55 70% 60%; /* Hellere Gelbnuance */
		--chart-12: 25 65% 50%; /* Warmer Orange-Braun-Ton */
		--chart-13: 5 65% 50%; /* Kräftiges Rot */
		--chart-14: 220 50% 35%; /* Marineblau */
		--chart-15: 250 45% 50%; /* Mittleres Blau-Violett */
		--chart-16: 280 50% 45%; /* Aubergine */
		--chart-17: 320 60% 55%; /* Magenta-Pink */
		--chart-18: 105 40% 45%; /* Oliviges Grün */
		--chart-19: 195 45% 25%; /* Sehr dunkles Petrol */
		--chart-20: 355 70% 65%; /* Helles Pinkrot */
		--chart-21: 60 70% 50%; /* Gelb-Grün */
		--chart-22: 120 50% 40%; /* Dunkelgrün */
		--chart-23: 180 60% 50%; /* Türkis */
		--chart-24: 240 50% 60%; /* Hellblau */
		--chart-25: 300 40% 50%; /* Violett */
		--chart-26: 360 70% 60%; /* Rot */
		--chart-27: 45 80% 55%; /* Orange */
		--chart-28: 90 50% 45%; /* Grün */
		--chart-29: 135 60% 50%; /* Türkis-Grün */
		--chart-30: 180 50% 40%; /* Blau */
	}

	.dark {
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}
